@import 'variables';

@font-face {
  font-family: stretchit;
  src: url('https://d2bkxke45bupbc.cloudfront.net/webapp/develop/assets/fonts/stretchit/stretchit.eot');
  src:
    url('https://d2bkxke45bupbc.cloudfront.net/webapp/develop/assets/fonts/stretchit/stretchit.eot')
    format('embedded-opentype'),
    url('https://d2bkxke45bupbc.cloudfront.net/webapp/develop/assets/fonts/stretchit/stretchit.ttf') format('truetype'),
    url('https://d2bkxke45bupbc.cloudfront.net/webapp/develop/assets/fonts/stretchit/stretchit.woff') format('woff'),
    url('https://d2bkxke45bupbc.cloudfront.net/webapp/develop/assets/fonts/stretchit/stretchit.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: stretchit, sans-serif !important;
  speak: none;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkbox-mark {
  &::before {
    content: $icon-checkbox-mark;
  }
}

.icon-date-add {
  &::before {
    content: $icon-date-add;
  }
}

.icon-date-remove {
  &::before {
    content: $icon-date-remove;
  }
}

.icon-date {
  &::before {
    content: $icon-date;
  }
}

.icon-facebook-o {
  &::before {
    content: $icon-facebook-o;
  }
}

.icon-achievement {
  &::before {
    content: $icon-achievement;
  }
}

.icon-arrow-left {
  &::before {
    content: $icon-arrow-left;
  }
}

.icon-arrow-right {
  &::before {
    content: $icon-arrow-right;
  }
}

.icon-audio {
  &::before {
    content: $icon-audio;
  }
}

.icon-caret-down {
  &::before {
    content: $icon-caret-down;
  }
}

.icon-caret-up {
  &::before {
    content: $icon-caret-up;
  }
}

.icon-checkmark {
  &::before {
    content: $icon-checkmark;
  }
}

.icon-close {
  &::before {
    content: $icon-close;
  }
}

.icon-complexity {
  &::before {
    content: $icon-complexity;
  }
}

.icon-delete {
  &::before {
    content: $icon-delete;
  }
}

.icon-dots {
  &::before {
    content: $icon-dots;
  }
}

.icon-edit {
  &::before {
    content: $icon-edit;
  }
}

.icon-facebook {
  &::before {
    content: $icon-facebook;
  }
}

.icon-flame {
  &::before {
    content: $icon-flame;
  }
}

.icon-flesh {
  &::before {
    content: $icon-flesh;
  }
}

.icon-info {
  &::before {
    content: $icon-info;
  }
}

.icon-instagram {
  &::before {
    content: $icon-instagram;
  }
}

.icon-lock {
  &::before {
    content: $icon-lock;
  }
}

.icon-maximize {
  &::before {
    content: $icon-maximize;
  }
}

.icon-minimize {
  &::before {
    content: $icon-minimize;
  }
}

.icon-pause {
  &::before {
    content: $icon-pause;
  }
}

.icon-play {
  &::before {
    content: $icon-play;
  }
}

.icon-star {
  &::before {
    content: $icon-star;
  }
}

.icon-time {
  &::before {
    content: $icon-time;
  }
}

.icon-twitter {
  &::before {
    content: $icon-twitter;
  }
}

.icon-unlock {
  &::before {
    content: $icon-unlock;
  }
}
