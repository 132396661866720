@import url("https://p.typekit.net/p.css?s=1&k=wba5iqs&ht=tk&f=137.139.175.24349.43309.43311&a=63387810&app=typekit&e=css");

@font-face {
  font-family:canada-type-gibson;
  src:url("https://use.typekit.net/af/3ebd6d/0000000000000000774d605d/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/3ebd6d/0000000000000000774d605d/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/3ebd6d/0000000000000000774d605d/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
  font-family:canada-type-gibson;
  src:url("https://use.typekit.net/af/1c2c04/0000000000000000774d5010/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/1c2c04/0000000000000000774d5010/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/1c2c04/0000000000000000774d5010/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}

@font-face {
  font-family:canada-type-gibson;
  src:url("https://use.typekit.net/af/f37390/0000000000000000774d500f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/f37390/0000000000000000774d500f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/f37390/0000000000000000774d500f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}
