@import "variables";

@mixin fonts() {
  font-style: normal;
  font-family: var(--st-font-primary);
  margin: 0;
}

h1, .h1 {
  font-size: 40px;
  line-height: 49px;

  @include fonts();

  font-weight: 700;
  text-transform: uppercase;

  @media (width <= 959px) {
    font-size: 28px;
    line-height: 34px;
  }

  @media (width <= 599px) {
    font-size: 20px;
    line-height: 24px;
  }
}

h2, .h2 {
  @include fonts();

  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
  text-transform: uppercase;

  @media (width <= 959px) {
    font-size: 24px;
    line-height: 29px;
  }

  @media (width <= 599px) {
    font-size: 18px;
    line-height: 22px;
  }
}

h3, .h3 {
  @include fonts();

  font-weight: 500;
  font-size: 24px;
  line-height: 26px;

  @media (width <= 959px) {
    font-size: 20px;
    line-height: 22px;
  }

  @media (width <= 599px) {
    font-size: 18px;
    line-height: 22px;
  }
}

h4, .h4 {
  @include fonts();

  font-weight: 500;
  font-size: 20px;
  line-height: 22px;

  @media (width <= 959px) {
    font-size: 18px;
    line-height: 20px;
  }

  @media (width <= 599px) {
    font-size: 16px;
    line-height: 19px;
  }
}

p, .t-base {
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;

  @media (width <= 599px) {
    font-size: 14px;
    line-height: 20px;
  }
}

.t-sm {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;

  @media (width <= 599px) {
    font-size: 12px;
    line-height: 17px;
  }
}

.t-xs {
  font-size: 12px;
  line-height: 17px;
  font-weight: 300;
}

.st-weight-400 {
  font-weight: 300;
}

.st-weight-900 {
  font-weight: 700;
}

.st-weight-700 {
  font-weight: 500;
}

.st-pointer {
  cursor: pointer;
}

.st-underline {
  text-decoration: underline;
  text-underline-offset: 3px;
}

.st-separator {
  position: relative;
  width: 16px;
  font-weight: 300;
  margin: 0 auto;

  &.german {
    width: fit-content;

    &::after {
      left: 46px;
    }
  }

  &.spanish {
  }

  &.portuguese {
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 70px;
    height: 1px;
    background: var(--st-color-grey);
  }

  &::before {
    left: -85px;
  }

  &::after {
    left: 30px;
  }
}

.st-border {
  width: 100%;
  height: 1px;
  background: var(--st-color-grey);
}

a:hover,
a:active {
  color: var(--st-color-black);
}

.inactive {
  * {
    color: var(--st-color-dark-grey);
  }
}

.loading {
  position: absolute;
  inset: 0;
  background: transparentize($white, 0.2);
  z-index: 999;
}

