.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @media (width >= 360px) {
    max-width: 100%;
  }

  @media (width >= 600px) {
    max-width: 600px;
  }

  @media (width >= 960px) {
    max-width: 960px;
  }

  @media (width >= 1200px) {
    max-width: 1200px;
  }
}

.quiz-header>div {
  width: 100%;
  display: flex;
  align-items: center;
  height: 80px;

  @media (width >= 960px) {
    height: 110px;
  }

  &.center {
    justify-content: center;
  }

  &.center-left-part {
    justify-content: center;

    @media (width >= 960px) {
      width: 50%;
    }
  }

  &.left {
    justify-content: center;

    @extend .container;

    @media (width >= 960px) {
      justify-content: left;
    }
  }
}

.quiz-title {
  position: sticky;
  top: 0;
  background: #fff;
  text-align: center;
  z-index: 10;
  margin-left: -12px;
  padding: 12px;
  width: calc(100% + 24px);
}

.min-h-mobile-screen {
  min-height: calc(100dvh);
}

.h-mobile-screen {
  height: calc(100dvh);
}

.h-screen-with-header {
  height: calc(100dvh - 80px) !important;

  @media (width >= 960px) {
    height: calc(100dvh - 110px) !important;;
  }
}

.tablet\:h-screen-with-header {
  @media (width >= 600px) {
    height: calc(100dvh - 80px) !important;;
  }

  @media (width >= 960px) {
    height: calc(100dvh - 110px) !important;;
  }
}

.macbook\:h-screen-with-header {
  @media (width >= 960px) {
    height: calc(100dvh - 110px) !important;;
  }
}

.desktop\:h-screen-with-header {
  @media (width >= 1400px) {
    height: calc(100dvh - 110px) !important;;
  }
}

.min-h-screen-with-header {
  min-height: calc(100dvh - 80px) !important;

  @media (width >= 960px) {
    min-height: calc(100dvh - 110px) !important;;
  }
}

.tablet\:min-h-screen-with-header {
  @media (width >= 600px) {
    min-height: calc(100dvh - 80px) !important;;
  }

  @media (width >= 960px) {
    min-height: calc(100dvh - 110px) !important;;
  }
}

.macbook\:min-h-screen-with-header {
  @media (width >= 960px) {
    min-height: calc(100dvh - 110px) !important;;
  }
}

.desktop\:min-h-screen-with-header {
  @media (width >= 1400px) {
    min-height: calc(100dvh - 110px) !important;;
  }
}

.max-h-screen-with-header {
  max-height: calc(100dvh - 80px) !important;

  @media (width >= 960px) {
    max-height: calc(100dvh - 110px) !important;;
  }
}

.tablet\:max-h-screen-with-header {
  @media (width >= 600px) {
    max-height: calc(100dvh - 80px) !important;;
  }

  @media (width >= 960px) {
    max-height: calc(100dvh - 110px) !important;;
  }
}

.macbook\:max-h-screen-with-header {
  @media (width >= 960px) {
    max-height: calc(100dvh - 110px) !important;;
  }
}

.desktop\:max-h-screen-with-header {
  @media (width >= 1400px) {
    max-height: calc(100dvh - 110px) !important;;
  }
}


.st-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  transition: scale 0.2s ease-in-out, border 0.2s ease-in-out;
  position: relative;

  .required-tooltip {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    left: -1px;
    top: -1px;
    background-color: var(--st-color-black);
    color: var(--st-color-white);
    font-size: 12px;
    font-weight: 300;
    padding: 4px;
    border-radius: 2px;

    @media (width >= 600px) {
      font-size: 14px;
    }
  }

  @media (hover: hover) {
    &:hover .required-tooltip {
      visibility: visible;
      left: 50%;
      transform: translate(-50%,-120%);

      &::after {
        content: " ";
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: var(--st-color-black) transparent transparent transparent;
      }
    }
  }

  @media (hover: none) {
    .required-tooltip {
      visibility: visible;
    }
  }


  &.solid {
    width: 100%;
    min-height: 41px;
    padding: 8px 15px;
    background-color: var(--st-color-black);
    color: var(--st-color-white);
    font-size: 12px;
    font-weight: 500;
    border-radius: 2px;

    @media (width >= 600px) {
      min-height: 45px;
      font-size: 12px;
      padding: 8px 20px;
    }

    @media (width >= 960px) {
      min-height: 49px;
    }

    .active {
      background-color: var(--st-color-dark);
    }

    @media (hover: hover) {
      &:not(.no-scale):hover {
        scale: 1.03;
      }

      &.no-scale:hover {
        background-color: var(--st-color-dark);
      }
    }

    &:active {
      background-color: var(--st-color-dark);
    }


    &[disabled] {
      background-color: var(--st-color-gray-dark);
      color: var(--st-color-white);
      cursor: not-allowed;

      @media (hover: hover) {
        &:hover {
          scale: 1;
        }
      }
    }
  }

  &.outline {
    width: 100%;
    min-height: 60px;
    padding: 8px 15px;
    border: 1px solid var(--st-color-gray-lines);
    font-size: 14px;
    font-weight: 300;
    outline-style: none;
    border-radius: 2px;

    @media (width >= 600px) {
      min-height: 72px;
      font-size: 16px;
      padding: 8px 20px;
    }

    &.active {
      border-color: var(--st-color-black);
    }

    @media (hover: hover) {
      &:not(.no-scale):hover {
        scale: 1.03;
      }

      &.no-scale:hover {
        border-color: var(--st-color-black);
      }
    }

    &:active {
      border-color: var(--st-color-black);
    }


    &[disabled] {
      color: var(--st-color-gray-dark);
      background-color: var(--st-color-gray-light);
      cursor: not-allowed;

      @media (hover: hover) {
        &:hover {
          scale: 1;
        }
      }
    }
  }

  &.circle {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: var(--st-color-white);

    svg {
      width: 24px;
      height: 24px;
    }

    @media (width <= 599px) {
      height: 24px;
      width: 24px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.st-input {
  display: block;
  width: 100%;
  background: var(--st-color-white);
  border: 1px solid var(--st-color-gray-lines);
  border-radius: 2px;
  font-weight: 300;
  padding: 14px 16px;
  color: var(--st-color-black);
  font-size: 16px;
  line-height: 19px;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
