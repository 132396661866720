@import "variables";

html {
  min-height: calc(100dvh);
}

.container {
  @media (width >= 1200px) {
    width: 1140px;
  }

  @media (width <= 767px) {
    width: 100%;
    max-width: 100% !important;
  }
}

body {
  font-family: var(--st-font-primary);
  min-width: 360px;
  position: relative;
  min-height: calc(100dvh);
  overflow-x: hidden;
  font-size: 16px;
  line-height: 1.2;
  color: var(--st-color-black);
}

input, textarea {
  outline-style: none;
}
